export enum PhoenixIcon {
  ALARM = 'alarm',
  ALARM_ON = 'alarm_on',
  ALARM_OFF = 'alarm_off',
  ASSIGNMENT = 'assignment',
  ASSIGNMENTLATE = 'assignment_late',
  ASSIGNMENTTURNEDIN = 'assignment_turned_in',
  AV_TIMER = 'av_timer',
  BLOCK = 'block',
  CHAT_BUBBLE = 'chat_bubble',
  CIRCLE_OUTLINE = 'circle_outline',
  CHECK_CIRCLE_OUTLINE = 'check_circle_outline',
  CREATE = 'create',
  DASHBOARD = 'dashboard',
  DELETE = 'delete',
  DEVELOPER_BOARD = 'developer_board',
  DEVICE_HUB = 'device_hub',
  DEVICES_OTHER = 'devices_other',
  EDIT = 'edit',
  FIBER_MANUAL_RECORD = 'fiber_manual_record',
  FORMAT_LINE_SPACING = 'format_line_spacing',
  GROUPS = 'groups',
  HELP = 'help',
  HOME = 'home',
  LENS = 'lens',
  MENU = 'menu',
  MY_LOCATION = 'my_location',
  NOTIFICATIONS = 'notifications',
  CIRCLE_NOTIFICATIONS = 'circle_notifications',
  NOTIFICATIONS_OFF = 'notifications_off',
  PANORAMA_FISH_EYE = 'panorama_fish_eye',
  PAUSE = 'pause',
  PEOPLE = 'people',
  PERSON = 'person',
  PHOTO_CAMERA = 'photo_camera',
  PLAYLIST_ADD_CHECK = 'playlist_add_check',
  PLAY_ARROW = 'play_arrow',
  REPEAT = 'repeat',
  SCHEDULE = 'schedule',
  CLOUD = 'cloud',
  SETTINGS_REMOTE = 'settings_remote',
  STAR_RATE = 'star_rate',
  STOP = 'stop',
  VIEW_HEADLINE = 'view_headline',
  VIEW_MODULE = 'view_module',
  TIMELAPSE = 'timelapse',
  SIGNAL_CELLULAR_ALT = 'signal_cellular_alt',
  BATTERY_CHARGING_FULL = 'battery_charging_full',
  LINK = 'link',
  STORE = 'store',
  ADD = 'add',
  CLONE = 'file_copy',
  ASSETS = 'folder',
  NO_MEASUREMENTS = 'horizontal_rule',
  INCOMPLETE_CIRCLE = 'incomplete_circle',
  RESTART_ALT = 'restart_alt',
  WIFI = 'wifi',
  WIFI_OFF = 'wifi_off',
  MORE_TIME = 'more_time',
  HOURGLASS_EMPTY = 'hourglass_empty',
  SMS = 'sms',
  PHONE_IPHONE = 'phone_iphone',
}
