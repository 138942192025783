// tslint:disable:max-file-line-count
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { Observer, Observable, concat, timer } from 'rxjs';

import { PhoenixAutocompleteEntry } from '../../components/phoenix-dialog/classes/phoenix-autocomplete-entry';
import { PhoenixDialogResult } from '../../components/phoenix-dialog/classes/phoenix-dialog-result';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '../../components/phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { AssetStatisticsDto } from '../../gapicon/asset/dto/asset-statistics-dto';
import { AssetTags } from '../../gapicon/asset/dto/asset-tags';
import { TypeCountDto } from '../../gapicon/asset/dto/type-count-dto';
import { AssetApiService } from '../../gapicon/asset/services/asset-api.service';
import { AssetDto, AssetSearchCriteriaDto } from '../../gapicon/dto.module';
import { PhoenixCommunicationSubject } from '../phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';

import { PhoenixMeasurementPointWizardService } from './phoenix-measurement-point-wizard.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import Timer = NodeJS.Timer;
import { UserGroupDto } from "../../gapicon/group/dto/user-group-dto";
import { GroupApiService } from "../../gapicon/group/services/group-api.service";

@Injectable({
  providedIn: 'root',
})
export class PhoenixAssetWizardService {
  private debouncer: Timer;
  private deleteAsset: AssetDto;
  private editedAsset: AssetDto;
  private openElementWizard: Function = undefined;

  public constructor(
    private dialog: MatDialog,
    private assetApiService: AssetApiService,
    private userGroupsApiService: GroupApiService,
    private translateService: TranslateService,
    private communicationService: PhoenixCommunicationService,
    private phoenixMeasurementPointWizardService: PhoenixMeasurementPointWizardService,
    private phoenixWizardHelper: PhoenixWizardHelperService,
    private snackbarService: PhoenixSnackbarService,
  ) {
  }

  public async openCreateAssetWizard(asset: AssetDto, parent?: AssetDto, openElementWizard?: Function): Promise<void> {
    this.editedAsset = asset;
    const userGroups = await this.userGroupsApiService.getGroups(false).toPromise();
    this.openElementWizard = openElementWizard;
    this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTH,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.ASSETS.CREATE.TITLE',
        subHeadline: 'WIZARDS.ASSETS.CREATE.SUBTITLE',
        buttons: {
          cancel: true,
          previous: !!this.openElementWizard,
          save: true,
        },
        formControls: await this.getFormControls(asset, parent, userGroups),
        onInit: (form: UntypedFormGroup): void => this.dialogOnInitCallback(form),
        onClose: (dialogResult: PhoenixDialogResult): Promise<void> => this.onNewAssetCloseCallback(dialogResult, userGroups, parent),
      },
    });
  }

  public async openDeleteAssetWizard(asset: AssetDto): Promise<void> {
    const randomString: string = this.phoenixWizardHelper.getRandomString();
    this.deleteAsset = asset;

    await this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTH,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.ASSETS.DELETE.TITLE',
        subHeadline: asset.name,
        buttons: {
          cancel: true,
          save: 'WIZARDS.ASSETS.DELETE.SUBMIT',
        },
        translationParams: {
          deleteCode: randomString,
        },
        color: 'warn',
        formControls: [
          _.concat(
            this.createTextFormControls(),
            this.createAssetChildCountIcons(asset.childStatistics),
            new PhoenixDynamicFormControl<string>(
              'delete-code',
              PhoenixDynamicFormControlType.Text,
              'WIZARDS.ASSETS.DELETE.DELETECODE',
              undefined,
              undefined,
              randomString,
            ),
            new PhoenixDynamicFormControl<string>(
              'delete',
              PhoenixDynamicFormControlType.TextBox,
              'WIZARDS.ASSETS.DELETE.DELETECODEINPUT',
              true,
              { hint: 'WIZARDS.ASSETS.DELETE.DELETECODEINPUTHINT' },
              undefined,
              undefined,
              [Validators.required, Validators.pattern(randomString)],
            ),
          ),
        ],
        onClose: (r: PhoenixDialogResult): Promise<void> => this.deleteAssetCallback(this.deleteAsset, r),
      },
    },
    );
  }

  public async openEditAssetWizard(asset: AssetDto): Promise<void> {
    this.editedAsset = asset;
    this.openElementWizard = undefined;
    let parentAsset: AssetDto;
    const userGroups = await this.userGroupsApiService.getGroups(false).toPromise();

    if (asset.parentId) {
      parentAsset = await this.assetApiService.getAssetById(asset.parentId).toPromise();
    }

    this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTH,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.ASSETS.EDIT.TITLE',
        subHeadline: 'WIZARDS.ASSETS.EDIT.SUBTITLE',
        buttons: {
          cancel: true,
          previous: !!this.openElementWizard,
          save: true,
        },
        formControls: await this.getFormControls(asset, parentAsset, userGroups),
        onInit: (form: UntypedFormGroup): void => this.dialogOnInitCallback(form),
        onClose: (dialogResult: PhoenixDialogResult): Promise<void> => this.onEditAssetCloseCallback(dialogResult, userGroups),
      },
    }).afterClosed().toPromise();
  }

  private createAssetChildCountIcons(childrenCount: AssetStatisticsDto): PhoenixDynamicFormControl<string> {
    const options: Object = { size: 40, classes: { 'hint-text': true } };
    const optionsWithFlippedIcon: Object = { size: 40, classes: { 'hint-text': true }, flipped: true };

    const alarmCount: number = _.sumBy(childrenCount.activeNotificationTypesCount, 'count') + _.sumBy(childrenCount.inactiveNotificationTypesCount, 'count');

    return new PhoenixDynamicFormControl<string>(
      'array1',
      PhoenixDynamicFormControlType.FormControlArray,
      undefined,
      false,
      {
        formControls: [
          new PhoenixDynamicFormControl<string>(
            'icon1',
            PhoenixDynamicFormControlType.Icon,
            _.sumBy(childrenCount.assetTypesCount, (stat: TypeCountDto) => stat.type !== AssetTags.measurementPoint ? stat.count : 0).toString(),
            false,
            options,
            PhoenixIcon.STOP,
          ),
          new PhoenixDynamicFormControl<string>(
            'icon2',
            PhoenixDynamicFormControlType.Icon,
            _.sumBy(childrenCount.assetTypesCount, (stat: TypeCountDto) => stat.type === AssetTags.measurementPoint ? stat.count : 0).toString(),
            false,
            options,
            PhoenixIcon.FIBER_MANUAL_RECORD,
          ),
          new PhoenixDynamicFormControl<string>(
            'icon3',
            PhoenixDynamicFormControlType.Icon,
            childrenCount.violationCount.toString(),
            false,
            optionsWithFlippedIcon,
            PhoenixIcon.PLAY_ARROW,
          ),
          new PhoenixDynamicFormControl<string>(
            'icon4',
            PhoenixDynamicFormControlType.Icon,
            alarmCount.toString(),
            false,
            options,
            PhoenixIcon.NOTIFICATIONS,
          ),
        ],
      },
    );
  }

  private createElementSelectionWizardColumn(
    key: string,
    icon: string,
    iconLabel: string,
    text: string,
    buttonLabel: string,
    flipIcon?: boolean,
    hint?: string,
    disabled?: boolean,
  ): PhoenixDynamicFormControl<string | boolean>[] {
    return [
      new PhoenixDynamicFormControl<string>(
        'icon' + key,
        PhoenixDynamicFormControlType.Icon,
        iconLabel,
        false,
        {
          size: 60,
          body: text,
          flip: flipIcon,
        },
        icon,
      ),
      new PhoenixDynamicFormControl<boolean>(
        'button' + key,
        PhoenixDynamicFormControlType.Button,
        buttonLabel,
        false,
        {
          style: 'raised',
          color: 'basic',
          disabled: disabled,
          hint: hint,
        },
        undefined,
        disabled,
      ),
    ];
  }

  private createTextFormControls(): PhoenixDynamicFormControl<string>[] {
    const hintTextOptions: { classes: { [key: string]: boolean } } = {
      classes: {
        'font-size-12': true,
        'hint-text': true,
        'primary-300-fg': true,
        'mt-4': true,
        'mb-4': true,
      },
    };

    const formControls: PhoenixDynamicFormControl<string>[] = [];

    const textToDisplay: string[] = [
      'WIZARDS.ASSETS.DELETE.LINE1',
      'WIZARDS.ASSETS.DELETE.LINE2',
      'WIZARDS.ASSETS.DELETE.LINE3',
      'WIZARDS.ASSETS.DELETE.LINE4',
      'WIZARDS.ASSETS.DELETE.LINE5',
      'WIZARDS.ASSETS.DELETE.LINE6',
      'WIZARDS.ASSETS.DELETE.LINE7',
      'WIZARDS.ASSETS.DELETE.LINE8',
      'WIZARDS.ASSETS.DELETE.CONFIRMATION',
    ];

    _.forEach(textToDisplay, (text: string) => {
      formControls.push(
        new PhoenixDynamicFormControl<string>(
          'text',
          PhoenixDynamicFormControlType.Text,
          undefined,
          false,
          hintTextOptions,
          text,
        ),
      );
    });

    return formControls;
  }

  private async deleteAssetCallback(asset: AssetDto, dialogResult: PhoenixDialogResult): Promise<void> {
    if (dialogResult.result === 'save') {
      const observables: Observable<void | number>[] = [];

      await this.getDeleteAssetObservablesRecursive(asset, observables);

      try {
        await concat(...observables).toPromise();
        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.ASSETS.DELETE.SUCCESS'));
      } catch (e) {
        console.error(e);
      }

      this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
    }

    dialogResult.dialogRef.close();
  }

  private dialogOnInitCallback(form: UntypedFormGroup): void {
    form.get('assetQrSwitch').valueChanges.subscribe(() => {
      const tags: string[] = form.get('assetTags').value;
      if (form.get('assetQrSwitch').value && !form.get('assetQrSwitch').disabled) {
        form.get('assetQr').enable();
        tags.pop();
        tags.push(AssetTags.checkpoint);
      } else {
        form.get('assetQr').disable();
        tags.pop();
        tags.push(AssetTags.asset);
      }
    });
  }

  private async getDeleteAssetObservablesRecursive(asset: AssetDto, observables: Observable<void | number>[]): Promise<void> {
    const childAssets: AssetDto[] = await this.assetApiService.getAssets(
      new AssetSearchCriteriaDto({ parentId: asset.id }),
    ).toPromise();

    for (const childAsset of childAssets) {
      await this.getDeleteAssetObservablesRecursive(childAsset, observables);
    }

    observables.push(
      timer(500),
      this.assetApiService.deleteAsset(asset),
    );
  }

  private async getFormControls(
    asset: AssetDto = new AssetDto({ tags: [AssetTags.asset] }),
    parent: AssetDto,
    usergroups: UserGroupDto[]): Promise<PhoenixDynamicFormControl<string | boolean | string[] | PhoenixAutocompleteEntry<AssetDto>>[][]> {
    const controls: PhoenixDynamicFormControl<string | boolean | string[] | PhoenixAutocompleteEntry<AssetDto>>[] = [];
    controls.push(new PhoenixDynamicFormControl<string>(
      'assetName',
      PhoenixDynamicFormControlType.TextBox,
      'WIZARDS.FORMFIELDS.ASSETNAME',
      true,
      {},
      _.get(asset, 'name', undefined),
      undefined,
      [Validators.required],
    ));
    controls.push(new PhoenixDynamicFormControl<string>(
      'assetDescription',
      PhoenixDynamicFormControlType.TextArea,
      'WIZARDS.FORMFIELDS.ASSETDESCRIPTION',
      false,
      {},
      _.get(asset, 'description', undefined),
    ));
    controls.push(new PhoenixDynamicFormControl<boolean>(
      'assetQrSwitch',
      PhoenixDynamicFormControlType.Switch,
      'WIZARDS.FORMFIELDS.ASSETQRSWITCH',
      false,
      {},
      asset.tags.includes(AssetTags.checkpoint),
      asset.id !== undefined,
    ));
    controls.push(new PhoenixDynamicFormControl<string>(
      'assetQr',
      PhoenixDynamicFormControlType.TextBox,
      'WIZARDS.FORMFIELDS.ASSETQR',
      false,
      { hint: 'WIZARDS.FORMFIELDS.ASSETQRHINT' },
      _.get(asset, 'code'),
      !asset.tags.includes(AssetTags.checkpoint),
      [Validators.pattern(new RegExp('^[a-zA-Z0-9]{0,64}$'))],
      [this.validateQrCode(_.get(asset, 'code'))],
    ));
    controls.push(new PhoenixDynamicFormControl<string[]>(
      'assetTags',
      PhoenixDynamicFormControlType.Chips,
      'WIZARDS.FORMFIELDS.TAGS',
      false,
      {},
      _.get(asset, 'tags', [AssetTags.asset]),
    ));
    controls.push(new PhoenixDynamicFormControl<PhoenixAutocompleteEntry<AssetDto>>(
      'assetParent',
      PhoenixDynamicFormControlType.TextBox,
      'WIZARDS.FORMFIELDS.PARENT',
      false,
      {
        hint: 'WIZARDS.FORMFIELDS.PARENTHINT',
        autocomplete: (search: string): Observable<PhoenixAutocompleteEntry<AssetDto>[]> => this.phoenixWizardHelper.assetAutocompleteSearchFunction(search, this.editedAsset),
      },
      parent ? new PhoenixAutocompleteEntry<AssetDto>({ object: parent, label: parent.name }) : undefined,
    ));

    controls.push(new PhoenixDynamicFormControl<string[]>(
      'userGroups',
      PhoenixDynamicFormControlType.Dropdown,
      'WIZARDS.ASSETS.ASSIGNGROUPS.ADDREMOVE',
      false,
      {
        multiple: true, options: this.getAssignedUserGroupsForSelect(usergroups),
      }, this.getAssignedUserGroupsForDisplay(asset.assignedUserGroups),
      undefined,
    ));

    return [controls];
  }


  private getAssignedUserGroupsForSelect(userGroups: UserGroupDto[]) {
    return userGroups
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((userGroup: UserGroupDto) => {
        return {
          key: userGroup.id,
          label: `${userGroup.name}`,
        };
      });
  }

  private getAssignedUserGroupsForDisplay(userGroups: UserGroupDto[]) {
    return userGroups
      .map((userGroup: UserGroupDto) => {
        return userGroup.id;
      });
  }

  private async onEditAssetCloseCallback(dialogResult: PhoenixDialogResult, userGroups: UserGroupDto[]): Promise<void> {
    if (dialogResult.result === 'save') {
      this.editedAsset.name = dialogResult.formData['assetName'];
      this.editedAsset.parentId = _.get(dialogResult.formData, 'assetParent.object.id', undefined);
      this.editedAsset.description = dialogResult.formData['assetDescription'];
      // Bugfix Steuerzeichen
      if (!_.isNil(this.editedAsset.description) && this.editedAsset.description.match('\u0000')) {
        this.editedAsset.description = undefined;
      }
      this.editedAsset.code = dialogResult.formData['assetQrSwitch'] ? (dialogResult.formData['assetQr'] || '') : undefined;
      this.editedAsset.tags = dialogResult.formData['assetTags'];


      try {
        let selectedGroupsIds = Array.from(dialogResult.formData['userGroups']);
        const newGroups = userGroups.filter(function (group: UserGroupDto) {
          return selectedGroupsIds.includes(group.id); // Filter out the appropriate one
        })

        this.editedAsset.assignedUserGroups = newGroups;

        await this.assetApiService.updateAsset(this.editedAsset).toPromise();
        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.ASSETS.EDIT.SUCCESS'));
      } catch (e) {
        console.error(e);
      }

      this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
    }
    dialogResult.dialogRef.close();
  }

  private async onFollowingCloseCallback(dialogResult: PhoenixDialogResult, parent?: AssetDto): Promise<void> {
    switch (dialogResult.formData['buttonClicked'].value) {
      case 'buttonDuplicateAsset':
        this.editedAsset.code = null;
        this.openCreateAssetWizard(this.editedAsset, parent, this.openElementWizard);
        break;
      case 'buttonNewAsset':
        this.openCreateAssetWizard(undefined, undefined, this.openElementWizard);
        break;
      case 'buttonSubMeasurementPoint':
        this.phoenixMeasurementPointWizardService.openCreateMeasurementPointWizard(undefined, this.editedAsset, this.openElementWizard);
        break;
      case 'buttonSubAsset':
        this.openCreateAssetWizard(undefined, this.editedAsset, this.openElementWizard);
        break;
    }
    dialogResult.dialogRef.close();
  }

  private async onNewAssetCloseCallback(dialogResult: PhoenixDialogResult, userGroups: UserGroupDto[], parent?: AssetDto): Promise<void> {
    if (dialogResult.result === 'save') {
      let asset: AssetDto = new AssetDto({
        name: dialogResult.formData['assetName'],
        parentId: _.get(dialogResult.formData, 'assetParent.object.id', undefined),
        description: dialogResult.formData['assetDescription'],
      });

      if (dialogResult.formData['assetQrSwitch']) {
        const qrCode: string = dialogResult.formData['assetQr'];
        asset.code = !_.isNil(qrCode) && qrCode.length > 0 ? qrCode : '';
        asset.tags.push(AssetTags.checkpoint);
      } else {
        asset.tags.push(AssetTags.asset);
      }

      try {
        let selectedGroupsIds = Array.from(dialogResult.formData['userGroups']);
        const newGroups = userGroups.filter(function (group: UserGroupDto) {
          return selectedGroupsIds.includes(group.id); // Filter out the appropriate one
        })

        asset.assignedUserGroups = newGroups;

        asset = await this.assetApiService.createAsset(asset).toPromise();
        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.ASSETS.CREATE.SUCCESS'));
      } catch (e) {
        console.error(e);
      }

      this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
      this.editedAsset = asset;
      this.openElementWizard = (): void => this.openFollowingElementWizard(asset, parent);
      this.openFollowingElementWizard(asset, parent);

    }
    if (dialogResult.result === 'previous' && this.openElementWizard !== undefined) {
      this.openElementWizard();
    }
    dialogResult.dialogRef.close();
  }

  private openFollowingElementWizard(asset: AssetDto, parent?: AssetDto): void {
    let subMeasurementPointColumn: PhoenixDynamicFormControl<string | boolean>[];
    let subAssetColumn: PhoenixDynamicFormControl<string | boolean>[];


    if (!asset.tags.includes(AssetTags.checkpoint)) {
      subMeasurementPointColumn = this.createElementSelectionWizardColumn(
        'SubMeasurementPoint',
        'arrow_forward fiber_manual_record',
        'WIZARDS.COMMON.SUBMEASUREMENTPOINT',
        'WIZARDS.SELECTOR.DESCRIPTION.SUBMEASUREMENTPOINT',
        'WIZARDS.SELECTOR.BUTTON.SUBMEASUREMENTPOINT',
        false,
        'WIZARDS.COMMON.NOMEASUREMENTPOINT',
        true,
      );
    } else {
      subMeasurementPointColumn = this.createElementSelectionWizardColumn(
        'SubMeasurementPoint',
        'arrow_forward fiber_manual_record',
        'WIZARDS.COMMON.SUBMEASUREMENTPOINT',
        'WIZARDS.SELECTOR.DESCRIPTION.SUBMEASUREMENTPOINT',
        'WIZARDS.SELECTOR.BUTTON.SUBMEASUREMENTPOINT',
      );
    }

    if (!asset.tags.includes(AssetTags.asset)) {
      subAssetColumn = this.createElementSelectionWizardColumn(
        'SubAsset',
        'arrow_forward stop',
        'WIZARDS.COMMON.SUBASSET',
        'WIZARDS.SELECTOR.DESCRIPTION.SUBASSET',
        'WIZARDS.SELECTOR.BUTTON.SUBASSET',
        true,
        'WIZARDS.COMMON.NOASSET',
        true,
      );
    } else {
      subAssetColumn = this.createElementSelectionWizardColumn(
        'SubAsset',
        'arrow_forward stop',
        'WIZARDS.COMMON.SUBASSET',
        'WIZARDS.SELECTOR.DESCRIPTION.SUBASSET',
        'WIZARDS.SELECTOR.BUTTON.SUBASSET',
        true,
      );
    }

    this.dialog.open(PhoenixDialogComponent, {
      width: '1150px',
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.SELECTOR.TITLE.FOLLOWINGASSET',
        subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.FOLLOWING',
        buttons: {
          exit: true,
        },
        translationParams: {
          assetName: asset.name,
        },
        onClose: (dialogResult: PhoenixDialogResult): Promise<void> => this.onFollowingCloseCallback(dialogResult, parent),
        formControls: [
          this.createElementSelectionWizardColumn(
            'DuplicateAsset',
            PhoenixIcon.REPEAT,
            'WIZARDS.COMMON.DUPLICATEASSET',
            'WIZARDS.SELECTOR.DESCRIPTION.DUPLICATEASSET',
            'WIZARDS.SELECTOR.BUTTON.DUPLICATEASSET',
          ),
          this.createElementSelectionWizardColumn(
            'NewAsset',
            PhoenixIcon.STOP,
            'WIZARDS.COMMON.NEWASSET',
            'WIZARDS.SELECTOR.DESCRIPTION.NEWASSET',
            'WIZARDS.SELECTOR.BUTTON.NEWASSET',
          ),
          subMeasurementPointColumn,
          subAssetColumn,
        ],
      },
    });
  }

  private validateQrCode(ownCode: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      clearTimeout(this.debouncer);
      return Observable.create((observer: Observer<ValidationErrors | null>) => {
        this.debouncer = setTimeout(() => {
          if (!_.isNil(control.value) && _.get(control, 'value.length', 0) > 0) {
            const sc: AssetSearchCriteriaDto = new AssetSearchCriteriaDto({ code: control.value });
            this.assetApiService.getAssets(sc).subscribe((next: AssetDto[]) => {
              const isOwnSensorCode: AssetDto = _.find(next, { code: ownCode });
              // tslint:disable-next-line
              const result = next.length === 0 || !_.isUndefined(isOwnSensorCode) ? null : { 'forbiddenName': { value: control.value } };
              if (!_.isNull(result)) {
                this.snackbarService.openPhoenixSnackbar(4000, 'right', 'top', this.translateService.instant('WIZARDS.FORMFIELDS.ASSETQRALREADYTAKEN'), 'red-snackbar');
              }
              observer.next(result);
              observer.complete();
            });
          } else {
            observer.next(undefined);
            observer.complete();
          }
        }, 1000);
      });
    };
  }
}
